<template>
  <div class="mt-4">
    <vs-table :data="initData.barang" stripe class="fix-table-input-background">
      <template slot="thead">
        <vs-th class="whitespace-no-wrap">#</vs-th>
        <vs-th class="whitespace-no-wrap">No. PR</vs-th>
        <vs-th class="whitespace-no-wrap">Nama Barang/Jasa</vs-th>
        <vs-th class="whitespace-no-wrap">Satuan</vs-th>
        <vs-th class="whitespace-no-wrap">Qty</vs-th>
        <vs-th class="whitespace-no-wrap">Harga</vs-th>
        <vs-th class="whitespace-no-wrap">File PR</vs-th>
        <vs-th class="whitespace-no-wrap">Total</vs-th>
      </template>
      <template slot-scope="{data}">
        <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
          <vs-td class="whitespace-no-wrap">{{ index + 1 }}</vs-td>
          <vs-td class="whitespace-no-wrap">{{ item.no_pengadaan }}</vs-td>
          <vs-td class="whitespace-no-wrap">{{ item.nama_item_pengadaan }}</vs-td>
          <vs-td class="whitespace-no-wrap">{{ item.satuan_item_pengadaan }}</vs-td>
          <vs-td class="whitespace-no-wrap">{{ item.qty }}</vs-td>
          <vs-td class="whitespace-no-wrap">{{ item.harga_satuan | idr }}</vs-td>
          <vs-td class="whitespace-no-wrap">
            <a :href="item.file_pr_url" target="_blank" v-if="item.file_pr_url">
              <vs-chip color="primary"><vs-avatar icon-pack="feather" icon="icon-file"/><span>File</span></vs-chip>
            </a>
          </vs-td>
          <vs-td class="whitespace-no-wrap text-right">{{ (item.harga_satuan * item.qty) | idr }}</vs-td>
        </vs-tr>
        <vs-tr class="text-sm font-semibold">
          <vs-td class="whitespace-no-wrap"></vs-td>
          <vs-td class="whitespace-no-wrap"></vs-td>
          <vs-td class="whitespace-no-wrap"></vs-td>
          <vs-td class="whitespace-no-wrap"></vs-td>
          <vs-td class="whitespace-no-wrap"></vs-td>
          <vs-td class="whitespace-no-wrap"></vs-td>
          <vs-td class="whitespace-no-wrap">TOTAL NILAI PO</vs-td>
          <vs-td class="whitespace-no-wrap text-right">{{ initData.header.nilai_total | idr }}</vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <div class="vx-row float-right mt-5">
      <div class="vx-col w-full flex">
        <vs-button @click="prev" type="border" class="mr-3" icon-pack="feather" icon="icon-arrow-left">Prev</vs-button>
        <vs-button @click="next" icon-pack="feather" icon="icon-arrow-right" icon-after>Next</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabBarang',
  props: {
    initData: { default: null, type: Object }
  },
  methods: {
    prev () {
      const currentActiveTab = this.$store.state.approvals.poKontrakApproval.activeTab
      this.$store.commit('approvals/poKontrakApproval/SET_ACTIVE_TAB', currentActiveTab - 1)
    },

    next () {
      const currentActiveTab = this.$store.state.approvals.poKontrakApproval.activeTab
      this.$store.commit('approvals/poKontrakApproval/SET_ACTIVE_TAB', currentActiveTab + 1)
    }
  }
}
</script>
